<template>
  <b-card>
    <validation-observer
      #default="{ invalid, handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        @submit.prevent="handleSubmit(updateMarketplaceProduct)"
      >

        <!-- Actions -->
        <b-row class="mb-4">
          <b-col>
            <b-button
              variant="secondary"
              class="float-right"
              size="sm"
              type="submit"
              :disabled="invalid"
            >
              <span class="text-nowrap">Save Changes</span>
            </b-button>
          </b-col>
        </b-row>

        <devices-marketplace-form
          :marketplace-product-data="marketplaceProductData"
          :status-options="statusOptions"
        />

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BForm, BButton,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import useDevicesMarketplaceForm from '../devices-marketplace-form/useDevicesMarketplaceForm'
import DevicesMarketplaceForm from '../devices-marketplace-form/DevicesMarketplaceForm.vue'
import { statusOptions } from '@/utils/computed-properties/system-codes/systemCodesComputedProperties'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,

    //  Local Components
    DevicesMarketplaceForm,

    //  Form Validation
    ValidationObserver,
  },
  data() {
    return {
      required,
    }
  },
  setup() {
    const {
      marketplaceProductData,

      fetchMarketplaceProduct,
      updateMarketplaceProduct,
    } = useDevicesMarketplaceForm()

    fetchMarketplaceProduct()

    return {
      //  Data
      marketplaceProductData,

      //   Options
      statusOptions,

      //  Actions
      fetchMarketplaceProduct,
      updateMarketplaceProduct,
    }
  },
}
</script>
